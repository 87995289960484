import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(
    private snackBar: MatSnackBar,
  ) { }

  public showToast(message: string, isError = false): void {
    this.snackBar.open(message, null, {
      duration: 2000,
      verticalPosition: 'top',
      panelClass: isError ? 'error' : 'success',
    });
  }
}
