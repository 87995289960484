/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/member-ordering */

import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

const DEFAULT_OPTIONS = {
  showCaption: true,
};

@Component({
  selector: 'proto-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GalleryComponent),
      multi: true,
    },
  ],
})
export class GalleryComponent implements ControlValueAccessor {
  @Input() public options: any = {};

  public items = [];
  public onChange: any = (): void => { };
  public onTouched: any = (): void => { };

  private _value = [];

  constructor() { }

  public ngOnInit(): void {
    this.options = Object.assign(DEFAULT_OPTIONS, this.options);
  }

  get value(): any {
    return this._value;
  }

  set value(val) {
    this.items = val;
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  public registerOnChange(fn): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn): void {
    this.onTouched = fn;
  }

  public writeValue(value): void {
    if (value) {
      this.value = value;
    }
  }

  public addImageToGallery(): void {
    this.items.push({ image: '', caption: '' });

    this.writeValue(this.items);
  }

  public removeGalleryItem(j: number): void {
    this.items.splice(j, 1);
  }

  public dropGalleryImage(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);
  }
}
