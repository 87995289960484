export const environment = {
  production: false,
  name: 'development',
  adminUrl: 'https://admin-dev.cawaena.com',
  // adminUrl: 'https://admin-dev.cawaena.com',
  //baseUrl shall be backend URL. Environment Specific
  baseUrl: 'http://api:3000',
  //baseUrl: 'http://api:3000',
  gecodingKey: 'AIzaSyBWofL-OT0ZZfh5sFfhNvRwHQI2b_QSEBQ',
  mapKey: 'AIzaSyBdcFMydB2OhLucCDSIt9GvxzvqB9TzBhg',
  // Environment Specific
  authUrl: 'https://cawaena-idm-rc.azurewebsites.net/api',
  matrixUrl: 'https://chat.rc.cawaena.com',
  // Keycloak RC url, with stg domain name
  keycloakUrl: 'https://keycloak.stg.cawaena.com/auth',
};
