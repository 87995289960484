export interface AdminSource {
  username: string;
  id: string;
  email: string;
  role: string;
  password: string;
  image: string;
  passwordNeedsChange: boolean;
  createdAt: string;
  updatedAt: string;
}

export class AdminModel {
  public id: string;
  public email: string;
  public role: string;
  public password: string;
  public passwordNeedsChange: boolean;
  public createdAt: string;
  public updatedAt: string;
  public username: string;

  constructor(source: AdminSource) {
    this.id = source.id;
    this.email = source.email;
    this.username = source.username;
    this.role = source.role;
    this.password = source.password;
    this.passwordNeedsChange = source.passwordNeedsChange;
    this.createdAt = source.createdAt;
    this.updatedAt = source.updatedAt;
  }
}
