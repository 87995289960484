import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AdminService } from 'apps/admin/src/app/admin/shared/admin.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public title = 'proto-admin';

  constructor(
    private adminService: AdminService,
    private translate: TranslateService,
  ) {
    // let lang = navigator.language.split('-')[0];
    // lang = /(de|en)/gi.test(lang) ? lang : 'de';

    this.translate.setDefaultLang('de');
    this.translate.use('de');

    this.init().catch();
  }

  private async init(): Promise<void> {
    await this.adminService.refreshCurrentAdmin().toPromise();
  }
}
