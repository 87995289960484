/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/member-ordering */

import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FileUploader, FileItem } from 'ng2-file-upload';

import { AuthService } from 'apps/admin/src/app/auth/shared/auth.service';

const DEFAULT_PREVIEW_WIDTH = 500;

import { C } from '../constants';
import { AdminService } from '../../admin/shared/admin.service';

@Component({
  selector: 'proto-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageUploadComponent),
      multi: true,
    },
  ],
})
export class ImageUploadComponent implements ControlValueAccessor {
  @Input() public previewWidth = DEFAULT_PREVIEW_WIDTH;
  @Input() public squarePreview = 0;
  @Input() public fileURl: string;
  @Input() public fileType: string;
  @Input() public fileUserId: string;

  public uploader: FileUploader;
  public imageUrl: string;

  public onChange: any = () => { };
  public onTouched: any = () => { };

  private _value = ''; 

  constructor(
    private authService: AuthService,
    private adminService: AdminService
  ) {
  }
  ngOnInit(): void {
    this.initializeUploader();
    this.fileUserId = this.fileUserId ? this.fileUserId : this.adminService.getCurrentAdmin().id;
  }
  get value(): any {
    return this._value;
  }

  set value(val) { 
    this._value = val;
    this.imageUrl = val ? this.fileURl : null;
    this.onChange(val);
    this.onTouched(); 
  }

  public removeImage(): void {
    this.value = null;
  }

  public registerOnChange(fn): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn): void {
    this.onTouched = fn;
  }

  public writeValue(value): void {
    if (value) {
      this.value = value;
    }
  }

  private initializeUploader(): void {
    this.uploader = new FileUploader({
      url: `${C.urls.files}/upload`,
      authToken: "Bearer "+this.authService.getAccessToken(),
    });
    this.uploader.onBuildItemForm = (fileItem: any, form: FormData): any => {
      fileItem.withCredentials = false;
      form.append('userId', this.fileUserId);
      return { fileItem, form };
    };

    this.uploader.onSuccessItem = (item: FileItem, response: any): void => {
      response = JSON.parse(response);
      this.value = response.id;
      this.imageUrl = response.publicUrl;
      this.fileType = response?.meta?.type;
    };

    this.uploader.onAfterAddingFile = (): void => {
      this.uploader.uploadAll();
    };
  }

  private getPreviewDimensions(): string {
    if (this.squarePreview) {
      return `square=${this.squarePreview}`;
    }

    return `width=${this.previewWidth}`;
  }
}

