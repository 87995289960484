<div class="wrapper">
  <div class="preview" *ngIf="fileUrl && file">
    {{ file.originalName }}
  </div>

  <div class="upload" *ngIf="!fileUrl">
    <input type="file" ng2FileSelect [uploader]="uploader" [accept]="acceptedFileTypes">
  </div>

  <div *ngIf="fileUrl" class="remove" (confirm)="removeFile();">
    <mat-icon>close</mat-icon>
  </div>
</div>
