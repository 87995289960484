/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/member-ordering */

import { Component, Input, forwardRef, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FileUploader, FileItem } from 'ng2-file-upload';

import { AuthService } from 'apps/admin/src/app/auth/shared/auth.service';
import { C } from '../constants';
import { HttpClient } from '@angular/common/http';
import { AdminService } from '../../admin/shared/admin.service';

const DEFAULT_PREVIEW_WIDTH = 500;

@Component({
  selector: 'proto-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true,
    },
  ],
})
export class FileUploadComponent implements ControlValueAccessor {
  @Input() public previewWidth = DEFAULT_PREVIEW_WIDTH;
  @Input() public squarePreview = 0;
  @Input() public acceptedFileTypes = 'application/pdf,image/png,image/jpeg';
  @Output() public onFileChange: EventEmitter<any> = new EventEmitter(null);
  @Input() public fileUserId: string;

  public uploader: FileUploader;
  public fileUrl: string;
  public file: any;

  public onChange: any = () => { };
  public onTouched: any = () => { };
  private _value: string = '';
  
  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private adminService: AdminService
  ) { 
  }
  ngOnInit(): void {
    this.initializeUploader();
  }
  get value(): any {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.fileUrl = val ? `${C.urls.files}/${this._value}/download` : null;
    this._value = val;
    this.fileUrl = val ? this.fileUrl : null;
    this.file = val ? this.fileUrl : null;
    this.getFileInfo();

    this.onChange(val);
    this.onTouched();
  }

  public removeFile(): void {
    this.value = null;

    this.onFileChange.emit(null);
  }

  public registerOnChange(fn): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn): void {
    this.onTouched = fn;
  }

  public writeValue(value): void {
    if (value) {
      this.value = value;
    }
  }

  private initializeUploader(): void {
    this.uploader = new FileUploader({
      url: `${C.urls.files}/upload`,
      authToken: "Bearer "+this.authService.getAccessToken(),
    });
    this.uploader.onBuildItemForm = (fileItem: any, form: FormData): any => {
      form.append('userId', this.fileUserId);
      return { fileItem, form };
    };
    this.uploader.onSuccessItem = (item: FileItem, response: any): any => {
      response = JSON.parse(response);

      this.value = response.id;
      this.fileUrl = response.publicUrl;
      this.file = response.publicUrl;
    };

    this.uploader.onAfterAddingFile = (): any => {
      this.uploader.uploadAll();
    };
  }

  private getFileInfo(): void {
    if (!this._value) { return; }

    this.http.get(`${C.urls.files}/${this._value}`)
      .toPromise()
      .then((file) => {
        this.file = file;

        this.onFileChange.emit(file);
      })
      .catch(() => {
        console.error();
      });
  }
}
