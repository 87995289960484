
<div class="module-wrapper">
  <div class="form-group">
    <div cdkDropList (cdkDropListDropped)="dropGalleryImage($event)" class="list">
      <div cdkDrag *ngFor="let galleryItem of items; let j = index;">
        <div class="box">
          <div class="module-wrapper">
            <proto-image-upload [(ngModel)]="galleryItem.image" squarePreview="200"></proto-image-upload>

            <mat-form-field appearance="outline" class="full-width" *ngIf="options.showCaption">
              <mat-label>{{ 'PROTO_EDITOR.CAPTION' | translate }}</mat-label>
              <input matInput type="text" [(ngModel)]="galleryItem.caption" placeholder="{{ 'PROTO_EDITOR.CAPTION' | translate }}">
            </mat-form-field>
          </div>
          <div class="handle" cdkDragHandle><mat-icon>menu</mat-icon></div>
          <div class="remove" (confirm)="removeGalleryItem(j);"><mat-icon>close</mat-icon></div>
        </div>
      </div>
      <div class="buttons">
        <button mat-raised-button (click)="addImageToGallery();">
          {{ 'PROTO_EDITOR.ADD_IMAGE' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
