import { Component, OnInit, Input } from '@angular/core';

import { C } from 'apps/admin/src/app/@shared/constants';

const DEFAULT_WIDTH = 90;

@Component({
  selector: 'proto-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class VideoComponent implements OnInit {
  @Input() public imageId: string;
  @Input() public fileURL: string;
  @Input() public width = DEFAULT_WIDTH;

  public videoUrl: string; 

  constructor() { }

  public ngOnInit(): void {
    if (!this.imageId) { return; } 
    // this.videoUrl = `${C.urls.files}/${this.imageId}/download?square=${this.width}`;
    this.videoUrl = this.fileURL;
  }
}
