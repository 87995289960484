import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';

import { AdminModel, AdminSource } from 'apps/admin/src/app/admin/shared/admin.model';
import { C } from 'apps/admin/src/app/@shared/constants';
import { StorageService } from 'apps/admin/src/app/@core/storage.service';
import { SecureStorageService } from '../../auth/shared/secure-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  public currentAdmin: BehaviorSubject<AdminModel | null> = new BehaviorSubject(null);

  constructor(
    private http: HttpClient,
    private storage: StorageService,
  ) {
    const currentAdmin = this.getCurrentAdmin();

    if (!currentAdmin) { return; }
    this.currentAdmin.next(currentAdmin);
  }

  public getCurrentAdmin(): AdminModel {
    const admin: AdminSource = this.storage.get('admin');
    if (admin) {
      return new AdminModel(admin);
    }

    return null;
  }

  public refreshCurrentAdmin(): Observable<AdminModel> {
    const currentAdmin = this.getCurrentAdmin();

    if (!currentAdmin) { return new Observable(null); }

    const url = `${C.urls.users}/${currentAdmin.id}`;

    return this.http.get<AdminSource>(url)
      .pipe(
        tap((admin) => {
          this.setCurrentAdmin(admin);
        }),
        map((admin) => new AdminModel(admin)),
      );
  }

  public getUserById(id: string): Promise<any> {
    return this.http.get(C.urls.getuserDetails(id)).toPromise();
  }

  public updatePassword(password: string): Promise<AdminSource> {
    const currentAdmin = this.getCurrentAdmin();

    if (!currentAdmin) { return; }

    const url = `${C.urls.admins}/${currentAdmin.id}`;

    return this.http.patch<AdminSource>(url, { password })
      .pipe(
        tap(() => {
          const admin: AdminSource = this.storage.get('admin');
          admin.passwordNeedsChange = false;
          this.setCurrentAdmin(admin);
        }),
      )
      .toPromise();
  }

  public setCurrentAdmin(admin: AdminSource): void {
    this.storage.set('admin', JSON.parse(JSON.stringify(admin)));
    const currentUser = new AdminModel(admin);
    this.currentAdmin.next(currentUser);
  }
  public removeCurrentAdmin(): void {
    this.storage.remove('admin');
    this.currentAdmin.next(null);
  }
}
