import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

interface ConfirmDialogContent {
  title: string;
  message: string;
  labelConfirm: string;
  labelCancel: string;
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  public title: string;
  public message: string;
  public labelConfirm: string;
  public labelCancel: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogContent,
  ) {
    this.title = data.title || this.title;
    this.message = data.message || this.message;
    this.labelConfirm = data.labelConfirm || this.labelConfirm;
    this.labelCancel = data.labelCancel || this.labelCancel;
  }

  public ngOnInit(): void { }
}
