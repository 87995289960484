import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AdminModel } from 'apps/admin/src/app/admin/shared/admin.model';
import { AdminService } from 'apps/admin/src/app/admin/shared/admin.service';
import { C } from 'apps/admin/src/app/@shared/constants';
import { StorageService } from 'apps/admin/src/app/@core/storage.service';
import { environment } from 'apps/admin/src/environments/environment';
import { ProtoReturnValue } from '../../@core/types';
import { SecureStorageService } from './secure-storage.service';
import { AuthPasswordResetDto } from '@blueprint/dtos';
import { isArray } from 'class-validator';
import Keycloak from 'keycloak-js';
import { Router } from '@angular/router';


export interface Credentials {
  email: string;
  password: string;
}
export interface AuthCredentials {
  email: string;
  password: string;
}
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public keycloak: Keycloak;
  tokenRefreshInterval:any;
  constructor(
    private adminService: AdminService,
    private http: HttpClient,
    private storage: StorageService,
    private sstorage: SecureStorageService,
    private router: Router,
  ) {
   }


  public static get envauthUrl(): string {
    return environment.authUrl;
  }



  public static get baseUrl(): string {
    return environment.baseUrl;
  }
  public static get url(): string {
    return `${this.baseUrl}/${this.apiVersion}`;
  }
  public static get apiVersion(): string {
    return 'api';
  }


  public static get authTokenurl(): string {
    return `${this.url}/auth/dtoken`;
  }

  public static get authRequestPasswordReset() {
    return (email: any): any => {
      return `${this.url}/auth/ForgotPassword/${email}`;
    };
  }

  public static get authRequestPasswordResetFinal() {
    return (): any => {
      return `${this.url}/auth/ResetPassword`;
    };
  }


  public static  get authsetRole() {
    return (userId: any): any => {
      //return `${this.envauthUrl}/${this.realm}/Users/${userId}/Clients/${environment.clientID}/Role`;
    };
  }

  public static get authResetPassword() {
    return (): string => {
      return `${this.url}/auth/reset-password`;
    };
  }


  public getAccessToken(): string {
    return this.storage.get('accessToken', null) as string;
  }

  isKeycloakAuthenticated(): boolean {
    return this.keycloak.authenticated || false;
  }

  // TODO: discuss if token is still necessary
  public isAuthenticated(){
    // const credentials = this.getCredentials();
    if(!!this.getAccessToken()){
      return true;
    }else{
      return false;
    }
  }

  // public async login(loginData): Promise<any>  {
  //   try {
  //     debugger
  //     //await this.getAuthToken();
  //     // const loginResponse =await  this.http.post<any>(AuthService.authLogin(), loginData).toPromise();
  //     const loginResponse =await this.http.post<any>(C.usersLogin(),loginData).toPromise();
  //     await this.storage.set('accessToken', loginResponse.access_token);
  //     await this.storage.set('refreshToken', loginResponse.refresh_token);
  //     if(loginResponse){
  //       // var userInfo:any=await this.http.get(AuthService.authUserByEmail(loginData.username)).toPromise();
  //       var userInfo:any = await this.http.get(C.usersMe()).toPromise();
  //       await this.storage.set('admin', userInfo as unknown as ProtoReturnValue);
  //       const updateuser = await this.adminService.getUserById(userInfo.id);
  //         if(loginResponse && updateuser){
  //           const roles:any =await this.http.post(AuthService.authTokenurl,{token:loginResponse.access_token}).toPromise();
  //           if(isArray(roles) && (roles.indexOf("admin") !== -1 || roles.indexOf("superadmin") !== -1 || roles.indexOf("Super Admin") !== -1)){
  //             const user = new AdminModel(userInfo);
  //             // await this.setCredentials(user.username, loginData.password);
  //             // ensure current user is set
  //             this.adminService.setCurrentAdmin(updateuser);
  //             const currentUser = this.adminService.getCurrentAdmin();
  //             if (!currentUser) {
  //               await this.adminService.setCurrentAdmin(updateuser);
  //             } else {
  //               this.adminService.currentAdmin.next(updateuser);
  //             }
  //             return Promise.resolve(user);
  //           }else{
  //             this.clearAdmin();
  //             return Promise.reject('admin');
  //           }

  //         }else{
  //           this.clearAdmin();
  //           return Promise.reject('There is no user available with this email!!');
  //         }
  //       } else{
  //         this.clearAdmin();
  //         return Promise.reject('Invalid Credentials');
  //       }

  //   } catch (error) {
  //     console.log(error);
  //     if(error.error){
  //       this.clearAdmin();
  //       return Promise.reject(error.error);
  //     }
  //     else{
  //       this.clearAdmin();
  //       return Promise.reject(error);
  //     }


  //   }
  // }

  public async clearAdmin(): Promise<any> {
    this.storage.remove('accessToken');
    this.sstorage.remove('credentials');
    this.adminService.removeCurrentAdmin();
    return Promise.resolve();
  }
  // public async logout(): Promise<any> {
  //   await this.http.get<void>(C.userLogout()).toPromise();
  //   this.storage.remove('accessToken');
  //   this.sstorage.remove('credentials');
  //   this.adminService.removeCurrentAdmin();
  //   return Promise.resolve();
  // }
  // public async getCredentials(): Promise<AuthCredentials> {
  //   let credentials: string | undefined;
  //   try {
  //     credentials = await this.storage.getnew('credentials');
  //   } catch (error) {
  //     console.error(error);
  //   }

  //   if (!credentials) {
  //     return Promise.resolve(null);
  //   }

  //   return Promise.resolve(JSON.parse(credentials));
  // }

  // public setCredentials(username: string, password: string): Promise<boolean> {
  //   return this.storage.setnew('credentials', JSON.stringify({
  //     username,
  //     password,
  //   }));
  // }
  public async requestPasswordReset(email:any) {
    return this.http.put<any>(AuthService.authRequestPasswordReset(email), { })
      .toPromise();
  }

  public requestPasswordResetFinal(data:any) {
    return this.http.put<any>(AuthService.authRequestPasswordResetFinal(),  data)
      .toPromise();
  }

  public resetPassword(token: string, newPassword: string): Promise<void> {
    const data: AuthPasswordResetDto = {
      token: token,
      password: newPassword,
    };
    return this.http.post<void>(AuthService.authResetPassword(), data)
      .toPromise();
  }


  async keycloakInit(){
    try{
      const keycloakConfig = {
        url: environment.keycloakUrl,
        realm: 'Cawaena',
        clientId: 'Cawaena_front_app'
      };
      this.keycloak = new Keycloak(keycloakConfig);
      const storedRefreshToken = await this.storage.get('refreshToken');
      const storedAccessToken = await this.storage.get('accessToken');

      // if (this.keycloak && storedRefreshToken && storedAccessToken) {
      //   this.keycloak.refreshToken = storedRefreshToken;
      //   this.keycloak.token = storedAccessToken
      // }
      // if(this.keycloak?.authenticated){
      //   await this.setCriticalData()
      // }
      // Binding Keycloak event in ionic context
     this.bindKeycloakEvents();
    if(!this.keycloak?.authenticated){
      await this.keycloak.init({
        enableLogging: true,
        onLoad:"check-sso",
        flow: "standard",
        // redirectUri:"http://localhost:4200/#/models",
        adapter:'default',
        silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
        pkceMethod:'S256',
      });
    }

      if (storedRefreshToken && storedAccessToken) {

        //Update Token Whenver User Open App for fresh instance of the access token
        await this.updateToken();
      }
      // this.startTokenRefreshTimer();
    }
      catch(error){
        console.error('Keycloak initialization error', error);
      }
  }

  async keycloakLogout(){
    const options = {
      redirectUri: `${environment.adminUrl}/auth/login`
  };
    try {
      await this.unsetCriticalData()
      await this.keycloak.clearToken();
      await this.keycloak.logout(options);
      console.log('Logged out successfully.');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  }

  async keycloaklogin() {
      const options: Keycloak.KeycloakLoginOptions = {
        redirectUri: `${environment.adminUrl}/#/models`,
        scope: 'openid offline_access'
    };
    try {
        await this.keycloak.login(options);

    } catch (error) {
        console.error('Login failed:', error);
    }
  }

  bindKeycloakEvents() {
      this.keycloak.onAuthLogout = () => {
       this.unsetCriticalData();
      };

      this.keycloak.onTokenExpired = () => {
        this.updateToken();

       };

      this.keycloak.onAuthSuccess = () => {
        console.log('Authenticated!');
        this.setCriticalData()
      };
  }

  async updateToken(){
    const refreshed = await this.keycloak.updateToken(-1);
    if (refreshed) {
      console.log('Token refreshed successfully');
      const newToken = this.keycloak.token;
      const newRefreshToken = this.keycloak.refreshToken;
      await this.storage.set('accessToken', newToken);
      await this.storage.set('refreshToken', newRefreshToken);

    }
  }

  async clearToken(){
    await this.keycloak.clearToken();
  }

  async setCriticalData(){
    await this.storage.set('accessToken', this.keycloak.token);
    await this.storage.set('refreshToken', this.keycloak.refreshToken);

    const roles = this.keycloak.tokenParsed.resource_access.Cawaena_front_app.roles;
     if(!(roles.includes('superadmin') || roles.includes('admin'))){
      await this.unsetCriticalData()
      await this.clearToken();
      await this.keycloak.logout();
      await this.router.navigate(['/auth/login']).catch();
      await this.deleteAllCookies();
      await this.storage.clear()
      alert('You are not admin!')
      return;
     }
     await this.router.navigate(['/models']).catch();
    var userInfo:any = await this.http.get(C.usersMe()).toPromise();
    await this.storage.set('admin', userInfo as unknown as ProtoReturnValue);
    const updateuser = await this.adminService.getUserById(userInfo.id);

    if(userInfo && updateuser){
     // const roles:any =await this.http.post(AuthService.authTokenurl,{token:this.keycloak.token}).toPromise();

      if(isArray(roles) && (roles.indexOf("admin") !== -1 || roles.indexOf("superadmin") !== -1 || roles.indexOf("Super Admin") !== -1)){
        const user = new AdminModel(userInfo);
        this.adminService.setCurrentAdmin(updateuser);
        const currentUser = this.adminService.getCurrentAdmin();
        if (!currentUser) {
          await this.adminService.setCurrentAdmin(updateuser);
        } else {
          this.adminService.currentAdmin.next(updateuser);
        }

      }
      else
      {
        await this.unsetCriticalData()
        window.location.reload()
      }
    }
  }

  async deleteAllCookies() {
    const cookies = document.cookie.split(';');
    const domainParts = window.location.hostname.split('.');

    cookies.forEach(cookie => {
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

      // Attempt to delete cookies set with different path and domain attributes
      const pathVariants = ['/', '/path']; // Add more paths if needed
      const domainVariants = [''].concat(
        domainParts.map((_, idx) => domainParts.slice(idx).join('.'))
      );

      pathVariants.forEach(path => {
        domainVariants.forEach(domain => {
          document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=${path}`;
          if (domain) {
            document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=${domain};path=${path}`;
          }
        });
      });
    });
  }

  async unsetCriticalData(){
    await this.adminService.removeCurrentAdmin();
    await this.storage.remove('accessToken');
    await this.storage.remove('refreshToken');

  }
}
