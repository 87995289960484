import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse,HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

import { AdminService } from 'apps/admin/src/app/admin/shared/admin.service';
import { C } from 'apps/admin/src/app/@shared/constants';
import { StorageService } from 'apps/admin/src/app/@core/storage.service';
import { SecureStorageService } from './secure-storage.service';
import { AuthService } from './auth.service';

const STATUS_UNAUTHORIZED = 401;

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private storage: StorageService,
    private adminService: AdminService,
    private sstorage: SecureStorageService,
    private http:HttpClient,
    private authService: AuthService
  ) { }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes(C.urls.url) && !request.url.includes('login') && !request.url.includes('reset-password')) {
      const accessToken = this.storage.get('accessToken') as string;

      if (accessToken) {
        request = request.clone({ headers: request.headers.set('Authorization', `Bearer ${accessToken}`) });
      }

      return next.handle(request).pipe(catchError((error) => {
        this.handleAuthError(error);

        return of(error);
      }) as any);
    }

    return next.handle(request);
  }

  // public async RefreshToken(token: string){
  //   const tokenData={
  //     'refresh_token':token,
  //   };
  //   try {
  //     const response: any =await this.http.post(C.refreshToken(),tokenData).toPromise();
  //     this.storage.set('refreshToken', response.refresh_token);
  //     this.storage.set('accessToken', response.access_token);
  //     window.location.reload();
  //   }catch (error) {
  //     return Promise.reject(error);
  //   }
  // }

  private handleAuthError(error: HttpErrorResponse): Observable<any> {
    //handle your auth error or rethrow
    if (error.status === STATUS_UNAUTHORIZED) {
      //navigate /delete cookies or whatever
      console.log('handled error ' + error.status);
      this.adminService.removeCurrentAdmin();
      this.storage.remove('accessToken');
      this.storage.remove('refreshToken');
      this.sstorage.remove('credentials');
      this.router.navigate(['/auth/login']).catch();
      console.error('Error: ' + error.message);

      // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
      return of(error.message);
    }

    if(error.status == 403){
      const token = this.storage.get('refreshToken');
      this.storage.remove('accessToken');
      this.storage.remove('refreshToken');
      window.location.reload()
    }
    throw error;
  }
}
