<div [ngStyle]="{ 'max-width': previewWidth + 'px' }" class="wrapper">
  <img class="preview" *ngIf="imageUrl && fileType =='image'" [src]="imageUrl" alt="Image to upload">
  <video *ngIf="imageUrl && fileType =='video'" controls style="width: 100%;">
    <source [src]="imageUrl" type="video/mp4">
    <source [src]="imageUrl" type="video/ogg">
    <source [src]="imageUrl" type="video/mov">
  Your browser does not support the video tag.
  </video>
  <div class="upload" *ngIf="!imageUrl" [ngStyle]="{ 'max-width': previewWidth + 'px' }">
    <input type="file" ng2FileSelect [uploader]="uploader">
  </div>
  <div *ngIf="imageUrl" class="remove" (confirm)="removeImage();">
    <mat-icon>close</mat-icon>
  </div>
</div>
