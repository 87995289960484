<div class="form-group list" cdkDropList
  (cdkDropListDropped)="dropDraggableItem($event)">
  <ng-container *ngFor="let item of items; let i = index;">
    <div class="box" *ngIf="options.modules.includes(item.type)" cdkDrag>

      <!-- Plain text module --------------------------------------------------->
      <div class="module-wrapper"
        *ngIf="item.type == 'text'">
        <div class="title">{{ 'PROTO_EDITOR.TEXT' | translate }}</div>
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{ 'PROTO_EDITOR.TEXT' | translate }}</mat-label>
          <textarea style="height: 100px"
            required="true"
            matInput
            placeholder="{{ 'PROTO_EDITOR.TEXT' | translate }}"
            [(ngModel)]="items[i].primitiveContent"></textarea>
        </mat-form-field>
      </div>

      <!-- Rich text module ---------------------------------------------------->
      <div class="module-wrapper"
        *ngIf="item.type == 'rtf'">
        <div class="title">{{ 'PROTO_EDITOR.RTF' | translate }}</div>

        <div class="form-group">
          <ngx-trumbowyg-editor
            [(ngModel)]="items[i].primitiveContent"></ngx-trumbowyg-editor>
        </div>
      </div>

      <!-- Images module ------------------------------------------------------->
      <div class="module-wrapper"
        *ngIf="item.type == 'images'">
        <div class="title">{{ 'PROTO_EDITOR.IMAGES' | translate }}</div>
        <div class="form-group">
          <div cdkDropList class="list"
            (cdkDropListDropped)="sortImage($event, i)">
            <div cdkDrag *ngFor="let galleryItem of items[i].mediaItems; let j = index;">
              <div class="box">
                <div class="module-wrapper">
                  <proto-image-upload
                    squarePreview="200"
                    [(ngModel)]="items[i].mediaItems[j].imageId"></proto-image-upload>

                  <mat-form-field appearance="outline" class="full-width">
                    <mat-label>{{ 'PROTO_EDITOR.TITLE' | translate }}</mat-label>
                    <input matInput type="text"
                      placeholder="{{ 'PROTO_EDITOR.TITLE' | translate }}"
                      [(ngModel)]="items[i].mediaItems[j].title">
                  </mat-form-field>

                  <mat-form-field appearance="outline" class="full-width">
                    <mat-label>{{ 'PROTO_EDITOR.DESCRIPTION' | translate }}</mat-label>
                    <input matInput type="text"
                      placeholder="{{ 'PROTO_EDITOR.DESCRIPTION' | translate }}"
                      [(ngModel)]="items[i].mediaItems[j].description">
                  </mat-form-field>

                  <mat-form-field appearance="outline" class="full-width">
                    <mat-label>{{ 'PROTO_EDITOR.COPYRIGHT' | translate }}</mat-label>
                    <input matInput type="text"
                      placeholder="{{ 'PROTO_EDITOR.COPYRIGHT' | translate }}"
                      [(ngModel)]="items[i].mediaItems[j].copyright">
                  </mat-form-field>
                </div>
                <div class="handle" cdkDragHandle>
                  <mat-icon>menu</mat-icon>
                </div>
                <div class="remove"
                  (confirm)="removeImage(i, j);">
                  <mat-icon>close</mat-icon>
                </div>
              </div>
            </div>
            <div class="buttons">
              <button mat-raised-button
                (click)="addImage(i);">
                {{ 'PROTO_EDITOR.ADD_IMAGE' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- External link module ------------------------------------------------>
      <div class="module-wrapper" *ngIf="item.type == 'external'">
        <div class="title">{{ 'PROTO_EDITOR.EXTERNAL' | translate }}</div>

        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{ 'PROTO_EDITOR.TITLE' | translate }}</mat-label>
          <input matInput type="text"
            required="true"
            placeholder="{{ 'PROTO_EDITOR.TITLE' | translate }}"
            [(ngModel)]="items[i].externalLink.title">
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{ 'PROTO_EDITOR.HREF' | translate }}</mat-label>
          <input matInput type="text"
            required="true"
            placeholder="{{ 'PROTO_EDITOR.HREF' | translate }}"
            [(ngModel)]="items[i].externalLink.href">
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{ 'PROTO_EDITOR.BUTTON_COLOR' | translate }}</mat-label>
          <mat-select
            [(ngModel)]="items[i].externalLink.color">
            <mat-option>--</mat-option>
            <mat-option
              *ngFor="let option of buttonColors"
              [value]="option">
              {{ option }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Video module -------------------------------------------------------->
      <div class="module-wrapper"
        *ngIf="item.type == 'video'">
        <div class="title">{{ 'PROTO_EDITOR.VIDEO_URL' | translate }}</div>

        <mat-form-field appearance="outline">
          <mat-label>{{ 'PROTO_EDITOR.VIDEO_TYPE' | translate }}</mat-label>
          <mat-select [(ngModel)]="items[i].mediaItems[0].videoType">
            <mat-option
              *ngFor="let option of videoTypes"
              [value]="option">
              {{ option }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{ 'PROTO_EDITOR.URL' | translate }}</mat-label>
          <input matInput type="text"
            required="true"
            placeholder="{{ 'PROTO_EDITOR.URL' | translate }}"
            [(ngModel)]="items[i].mediaItems[0].src">
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{ 'PROTO_EDITOR.TITLE' | translate }}</mat-label>
          <input matInput type="text"
            placeholder="{{ 'PROTO_EDITOR.TITLE' | translate }}"
            [(ngModel)]="items[i].mediaItems[0].title">
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{ 'PROTO_EDITOR.DESCRIPTION' | translate }}</mat-label>
          <input matInput type="text"
            placeholder="{{ 'PROTO_EDITOR.DESCRIPTION' | translate }}"
            [(ngModel)]="items[i].mediaItems[0].description">
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{ 'PROTO_EDITOR.COPYRIGHT' | translate }}</mat-label>
          <input matInput type="text"
            placeholder="{{ 'PROTO_EDITOR.COPYRIGHT' | translate }}"
            [(ngModel)]="items[i].mediaItems[0].copyright">
        </mat-form-field>
      </div>

      <!-- Files module -------------------------------------------------------->
      <div class="module-wrapper"
        *ngIf="item.type == 'files'">
        <div cdkDropList class="list"
          (cdkDropListDropped)="sortFile($event, i)">
          <div cdkDrag *ngFor="let file of items[i].mediaItems; let j = index;">
            <div class="box">
              <div class="module-wrapper">

                <proto-file-upload
                  [(ngModel)]="items[i].mediaItems[j].fileId"
                  (onFileChange)="fileUploadFileChanged($event, i, j)"></proto-file-upload>

                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>{{ 'PROTO_EDITOR.TITLE' | translate }}</mat-label>
                  <input matInput type="text"
                    placeholder="{{ 'PROTO_EDITOR.TITLE' | translate }}"
                    [(ngModel)]="items[i].mediaItems[j].title">
                </mat-form-field>

                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>{{ 'PROTO_EDITOR.DESCRIPTION' | translate }}</mat-label>
                  <input matInput type="text"
                    placeholder="{{ 'PROTO_EDITOR.DESCRIPTION' | translate }}"
                    [(ngModel)]="items[i].mediaItems[j].description">
                </mat-form-field>

                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>{{ 'PROTO_EDITOR.COPYRIGHT' | translate }}</mat-label>
                  <input matInput type="text"
                    placeholder="{{ 'PROTO_EDITOR.COPYRIGHT' | translate }}"
                    [(ngModel)]="items[i].mediaItems[j].copyright">
                </mat-form-field>
              </div>
              <div class="handle" cdkDragHandle>
                <mat-icon>menu</mat-icon>
              </div>
              <div class="remove"
                (confirm)="removeFile(i, j);">
                <mat-icon>close</mat-icon>
              </div>
            </div>
          </div>
          <div class="buttons">
            <button mat-raised-button
              (click)="addFile(i);">
              {{ 'PROTO_EDITOR.ADD_FILE' | translate }}
            </button>
          </div>
        </div>
      </div>

      <div class="remove"
        (confirm)="removeDraggableItem(i);">
        <mat-icon>close</mat-icon>
      </div>
      <div class="handle" cdkDragHandle>
        <mat-icon>menu</mat-icon>
      </div>
    </div>
  </ng-container>
</div>

<div class="buttons">
  <button mat-raised-button
    *ngIf="options.modules.includes('text')"
    (click)="addEmptyItem('text');">
    {{ 'PROTO_EDITOR.ADD_TEXT' | translate }}
  </button>

  <button mat-raised-button
    *ngIf="options.modules.includes('rtf')"
    (click)="addEmptyItem('rtf');">
    {{ 'PROTO_EDITOR.ADD_RTE' | translate }}
  </button>

  <button mat-raised-button
    *ngIf="options.modules.includes('images')"
    (click)="addEmptyItem('images');">
    {{ 'PROTO_EDITOR.ADD_IMAGES' | translate }}
  </button>

  <button mat-raised-button
    *ngIf="options.modules.includes('external')"
    (click)="addEmptyItem('external');">
    {{ 'PROTO_EDITOR.ADD_EXTERNAL' | translate }}
  </button>

  <button mat-raised-button
    *ngIf="options.modules.includes('video')"
    (click)="addEmptyItem('video');">
    {{ 'PROTO_EDITOR.ADD_VIDEO_URL' | translate }}
  </button>

  <button mat-raised-button
    *ngIf="options.modules.includes('files')"
    (click)="addEmptyItem('files');">
    {{ 'PROTO_EDITOR.ADD_FILES' | translate }}
  </button>
</div>
