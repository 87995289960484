/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from 'apps/admin/src/app/auth/shared/auth.guard';
import { PasswordChangeGuard } from 'apps/admin/src/app/auth/shared/password-change.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo:'models',
    pathMatch: 'full',
  },
  {
    path: 'models',
    canActivate: [AuthGuard, PasswordChangeGuard],
    loadChildren: () => import('apps/admin/src/app/model/model.module').then((mod) => mod.ModelModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('apps/admin/src/app/auth/auth.module').then((mod) => mod.AuthModule),
  },
  // {
  //   path: '**',
  //   redirectTo: 'auth/login',
  //   pathMatch: 'full',
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false, useHash: true, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
// tslint:disable-next-line
export class AppRoutingModule { }
