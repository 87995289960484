import { Directive, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ConfirmDialogComponent } from 'apps/admin/src/app/@shared/confirm-dialog/confirm-dialog.component';

@Directive({
  selector: '[confirm]',
})
export class ConfirmDirective {
  @Input() public title: string;
  @Input() public message: string;
  @Input() public labelCancel: string;
  @Input() public labelConfirm: string;
  @Output() public confirm = new EventEmitter<any>();

  constructor(
    private matDialog: MatDialog,
  ) { }

  @HostListener('click')
  public onClick(): void {
    this.openDialog();
  }

  private openDialog(): void {
    const dialog = this.matDialog.open(ConfirmDialogComponent, {
      width: '275px',
      data: {
        title: this.title,
        message: this.message,
        labelCancel: this.labelCancel,
        labelConfirm: this.labelConfirm,
      },
    });

    dialog.afterClosed().subscribe((confirmation) => {
      if (confirmation) {
        this.confirm.emit();
      }
    });
  }
}
