import { Component, OnInit, Input } from '@angular/core';

import { C } from 'apps/admin/src/app/@shared/constants';

const DEFAULT_WIDTH = 250;

@Component({
  selector: 'proto-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent implements OnInit {
  @Input() public imageId: string;
  @Input() public fileURL: string;
  @Input() public width = DEFAULT_WIDTH;

  public imageUrl: string; 

  constructor() { }

  public ngOnInit(): void {
    if (!this.imageId) { return; }    
    // this.imageUrl = `${C.urls.files}/${this.imageId}/download?square=${this.width}`;
    this.imageUrl = this.fileURL;
  }
}
