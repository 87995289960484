import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FileUploadModule } from 'ng2-file-upload';
import { MomentModule } from 'ngx-moment';
import { NgxTrumbowygModule } from 'ngx-trumbowyg';
import { TranslateModule } from '@ngx-translate/core';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';

import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ConfirmDirective } from 'apps/admin/src/app/@shared/confirm.directive';
import { FileUploadComponent } from 'apps/admin/src/app/@shared/file-upload/file-upload.component';
import { GalleryComponent } from 'apps/admin/src/app/@shared/gallery/gallery.component';
import { ImageComponent } from './image/image.component'; 
import { ImageUploadComponent } from 'apps/admin/src/app/@shared/image-upload/image-upload.component';
import { MaterialModule } from 'apps/admin/src/app/@material/material.module';
import { ProtoEditorComponent } from './proto-editor/proto-editor.component';
import { VideoComponent } from './video/video.component';

@NgModule({
  declarations: [
    ConfirmDialogComponent,
    ConfirmDirective,
    FileUploadComponent,
    GalleryComponent,
    ImageComponent,
    VideoComponent,
    ImageUploadComponent,
    ProtoEditorComponent,
  ],
  imports: [
    CommonModule,
    FileUploadModule,
    FormsModule,
    MomentModule,
    NgxJsonViewerModule,
    NgxTrumbowygModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ReactiveFormsModule,
    MaterialModule,
    TranslateModule,
  ],
  exports: [
    ConfirmDirective,
    ConfirmDialogComponent,
    FileUploadComponent,
    FileUploadModule,
    FormsModule,
    GalleryComponent,
    ImageComponent,
    VideoComponent,
    ImageUploadComponent,
    MomentModule,
    NgxJsonViewerModule,
    NgxTrumbowygModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ProtoEditorComponent,
    ReactiveFormsModule,
    MaterialModule,
    TranslateModule,
  ],
  entryComponents: [
    ConfirmDialogComponent,
  ],
  providers: [
    {
      provide: OWL_DATE_TIME_LOCALE, useValue: 'de',
    },
  ],
})
// tslint:disable-next-line
export class SharedModule { }
