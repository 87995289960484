import { Injectable } from '@angular/core';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { C } from '../../@shared/constants';

@Injectable({
  providedIn: 'root',
})
export class SecureStorageService {
  constructor(
  ) {}

  public async get(key: string): Promise<string> {
    let result: { value?: string } = {};

    try {
      result = await SecureStoragePlugin.get({ key: C.STORAGE_PREFIX + key });
    } catch (error) {
      console.error('secure storage', error, key);
    }

    return Promise.resolve(result.value);
  }

  public async set(key: string, value: string): Promise<boolean> {
    let succeeded: { value?: boolean } = {};

    try {
      succeeded = await SecureStoragePlugin.set({ key: C.STORAGE_PREFIX + key, value: value });
    } catch (error) {
      console.error('secure storage', error, key);
    }

    return Promise.resolve(succeeded.value);
  }

  public async remove(key: string): Promise<boolean> {
    let succeeded: { value?: boolean } = {};

    try {
      succeeded = await SecureStoragePlugin.remove({ key: C.STORAGE_PREFIX + key });
    } catch (error) {
      console.error('secure storage', error, key);
    }

    return Promise.resolve(succeeded.value);
  }

  public async clear(): Promise<boolean> {
    let succeeded: { value?: boolean } = {};

    try {
      succeeded = await SecureStoragePlugin.clear();
    } catch (error) {
      console.error('secure storage', error);
    }

    return Promise.resolve(succeeded.value);
  }
}
