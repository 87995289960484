import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FileSaverModule } from 'ngx-filesaver';
import { NgxTrumbowygModule } from 'ngx-trumbowyg';

import { AppComponent } from 'apps/admin/src/app/app.component';
import { AppRoutingModule } from 'apps/admin/src/app/app-routing.module';
import { CoreModule } from 'apps/admin/src/app/@core/core.module';
import { MaterialModule } from 'apps/admin/src/app/@material/material.module';
import { SharedModule } from 'apps/admin/src/app/@shared/shared.module';
import { AuthService } from './auth/shared/auth.service';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

function initializeKeycloak(authService: AuthService) {
  return () => authService.keycloakInit();
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    FileSaverModule,
    HttpClientModule,
    MaterialModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient],
      },
    }),
    NgxTrumbowygModule.withConfig({
      lang: 'de_DE',
      svgPath: './assets/icons/trumbowyg-icons.svg',
      btns: [
        ['viewHTML'],
        ['undo', 'redo'],
        ['formatting'],
        ['strong', 'em', 'del'],
        ['superscript', 'subscript'],
        ['link'],
        // ['insertImage'],
        ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
        ['unorderedList', 'orderedList'],
        ['horizontalRule'],
        ['removeformat'],
        ['fullscreen'],
      ],
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      deps: [AuthService],
      multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
