/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/member-ordering */

import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

// TODO: This should be shared with Blueprint
type ContentBlockType = 'text' | 'rtf' | 'images' | 'video' | 'external' | 'files' | 'audio';
type VideoType = 'youtube' | 'vimeo';
type ButtonColor = 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger' | 'light' | 'medium' | 'dark';

export interface MetaDataSource {
  title?: string;
  description?: string;
  copyright?: string;
}

export interface MediaSource extends MetaDataSource {
  src: string;
}

export interface ImageSource extends MetaDataSource {
  imageId: string;
}

export interface VideoSource extends MediaSource {
  videoType: VideoType;
}

export interface FileSource extends MetaDataSource {
  fileId: string;
  mimeType: MimeType;
}

export interface ExternalLinkSource {
  href: string;
  title: string;
  color?: ButtonColor;
}

interface IContentBlockSource {
  type: ContentBlockType;
}

export type ContentBlockSource = IContentBlockSource & {
  type: 'text' | 'rtf';
  primitiveContent: string;
} | {
  type: 'images';
  mediaItems: ImageSource[];
} | {
  type: 'video';
  mediaItems: VideoSource[];
} | {
  type: 'external';
  externalLink: ExternalLinkSource;
} | {
  type: 'files' | 'audio';
  mediaItems: FileSource[];
};

const DEFAULT_OPTIONS = {
  modules: ['rtf', 'images', 'external', 'files', 'video', 'text'],
};

const EMPTY_IMAGE: ImageSource = {
  imageId: '',
  title: '',
  description: '',
  copyright: '',
};

const EMPTY_EXTERNAL: ExternalLinkSource = {
  href: '',
  title: '',
  color: 'primary',
};

const EMPTY_VIDEO: VideoSource = {
  videoType: 'youtube',
  src: '',
  title: '',
  description: '',
  copyright: '',
};

const EMPTY_FILE: FileSource = {
  fileId: '',
  mimeType: null,
  title: '',
  description: '',
  copyright: '',
};

@Component({
  selector: 'proto-editor',
  templateUrl: './proto-editor.component.html',
  styleUrls: ['./proto-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProtoEditorComponent),
      multi: true,
    },
  ],
})
// TODO: I'm not sure directly writing into the `public items` is a good approach here.
// It'b be preferable if each content type had their own component that emits a ContentBlockSource
// on change and this is handled type-safely in this editor component
export class ProtoEditorComponent implements ControlValueAccessor {
  @Input() public options: any = {};

  public buttonColors: ButtonColor[] = [
    'primary',
    'secondary',
    'dark',
    'medium',
    'light',
    'success',
    'danger',
    'warning',
  ];

  public videoTypes: VideoType[] = [
    'youtube',
    'vimeo',
  ];

  // TODO: What is the difference between items and _value?
  public items: ContentBlockSource[] = [];
  public onChange: any = () => { };
  public onTouched: any = () => { };
  private _value: ContentBlockSource[] = [];
  
  constructor() { }
  
  public ngOnInit(): void {
    this.options = Object.assign(DEFAULT_OPTIONS, this.options);
  }

  get value(): any {
    return this._value;
  }

  set value(val) {
    this.items = val;
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  public registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  // TODO: Is it a good pattern to always write the whole array?
  public writeValue(value: ContentBlockSource[]): void {
    if (value) {
      this.value = value;

      return;
    }

    console.warn('writeValue: No value set');
  }

  public addEmptyItem(type: ContentBlockType): void {
    switch (type) {
      case 'text':
        this.items.push({ type: type, primitiveContent: null });
        break;

      case 'rtf':
        this.items.push({ type: type, primitiveContent: null });
        break;

      case 'images':
        this.items.push({ type: type, mediaItems: [Object.assign({}, EMPTY_IMAGE)] });

        break;

      case 'external':
        this.items.push({ type: type, externalLink: Object.assign({}, EMPTY_EXTERNAL) });
        break;

      case 'video':
        this.items.push({ type: type, mediaItems: [Object.assign({}, EMPTY_VIDEO)] });
        break;

      case 'files':
        this.items.push({ type: type, mediaItems: [Object.assign({}, EMPTY_FILE)] });
        break;

      default:
        console.error('Unknown type.');
        break;
    }

    this.writeValue(this.items);
  }

  public removeDraggableItem(i: number): void {
    console.debug('removeItem', i);
    this.items.splice(i, 1);
  }

  public dropDraggableItem(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);
  }

  public addImage(i: number): void {
    const contentBlock = this.items[i];

    if (contentBlock.type !== 'images') {
      console.warn('addImage is only available for type images');

      return;
    }

    contentBlock.mediaItems.push(Object.assign({}, EMPTY_IMAGE));
    this.writeValue(this.items);
  }

  public removeImage(i: number, j: number): void {
    const contentBlock = this.items[i];

    if (contentBlock.type !== 'images') {
      console.warn('removeImage is only available for type images');

      return;
    }

    contentBlock.mediaItems.splice(j, 1);
  }

  public sortImage(event: CdkDragDrop<string[]>, i: number): void {
    const contentBlock = this.items[i];

    if (contentBlock.type !== 'images') {
      console.warn('sortImage is only available for type images');

      return;
    }

    moveItemInArray(contentBlock.mediaItems, event.previousIndex, event.currentIndex);
  }

  public addFile(i: number): void {
    const contentBlock = this.items[i];

    if (contentBlock.type !== 'files' && contentBlock.type !== 'audio') {
      console.warn('addFile is only available for type files or audio');

      return;
    }

    contentBlock.mediaItems.push(EMPTY_FILE);
    this.writeValue(this.items);
  }

  public removeFile(i: number, j: number): void {
    const contentBlock = this.items[i];

    if (contentBlock.type !== 'files' && contentBlock.type !== 'audio') {
      console.warn('removeFile is only available for type files or audio');

      return;
    }

    contentBlock.mediaItems.splice(j, 1);
  }

  public sortFile(event: CdkDragDrop<string[]>, i: number): void {
    const contentBlock = this.items[i];

    if (contentBlock.type !== 'files' && contentBlock.type !== 'audio') {
      console.warn('sortFile is only available for type files or audio');

      return;
    }
    moveItemInArray(contentBlock.mediaItems, event.previousIndex, event.currentIndex);
  }

  public fileUploadFileChanged(event: any, i: number, j: number): void {
    const contentBlock = this.items[i];

    if (contentBlock.type !== 'files' && contentBlock.type !== 'audio') {
      console.warn('fileUploadFileChanged is only available for type files or audio');

      return;
    }

    const fileToUpdate = contentBlock.mediaItems[j];

    if (!event) {
      fileToUpdate.mimeType = null;
      fileToUpdate.fileId = '';

      return;
    }

    if (!fileToUpdate.title) {
      fileToUpdate.title = event.originalName;
      fileToUpdate.mimeType = event.mimeType;
    }

    if (!fileToUpdate.mimeType) {
      fileToUpdate.mimeType = event.mimeType;
    }
  }
}
