import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';

import { AdminService } from 'apps/admin/src/app/admin/shared/admin.service';

@Injectable({
  providedIn: 'root',
})
export class PasswordChangeGuard implements CanActivate {
  constructor(
    private adminService: AdminService,
    private router: Router,
  ) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const currentAdmin = this.adminService.getCurrentAdmin();

    if (!currentAdmin || !currentAdmin.passwordNeedsChange) { return true; }

    this.router.navigate(['/auth/password-change'], { queryParams: { returnUrl: state.url } }).catch();

    return false;
  }
}
