import { environment } from '../../environments/environment';
import { ProtoParams, ProtoWhereFilter } from '../@core/types';
type UrlParams = {
  queryParams?: ProtoParams;
  filter?: {
    where?: ProtoWhereFilter;
    order?: any;
    skip?: number;
    take?: number;
    relations?: string[];
  };
};
/* tslint:disable-next-line */
export class C {
  public static ENV = environment.name;

  public static STORAGE_PREFIX_SEPARATOR = 'ಠ_ಠ';
  public static STORAGE_APP_PREFIX = 'protoadmin';
  public static STORAGE_PREFIX = `${C.STORAGE_APP_PREFIX}-${environment.name}${C.STORAGE_PREFIX_SEPARATOR}`;

  public static urls: any = {
    get baseUrl() {
      return environment.baseUrl;
    },
    apiVersion: 'api',
    get url() {
      return this.baseUrl + '/' + this.apiVersion;
    },
    get files() {
      return this.url + '/files';
    },
    get admins() {
      return this.url + '/admins';
    },
    get users() {
      return this.url + '/users';
    },
    get export() {
      return this.url + '/export';
    },
    get adminConfig() {
      return this.url + '/admin-config';
    },
    get auth() {
      return this.url + '/auth';
    },
    get getuserDetails() {
      return (id: string): any => {
        const filter = {
          "relations": ["roles"]
        }
        return `${this.url}/users/${id}?filter=${encodeURIComponent(JSON.stringify(filter))}`;
      };
    }
  };
  public static get authUrl(): string {
    return environment.authUrl;
  }
  public static get baseUrl(): string {
    return environment.baseUrl;
  }
  public static get apiVersion(): string {
    return 'api';
  }
 
  public static get url(): string {
    return `${C.baseUrl}/${C.apiVersion}`;
  }
  public static colors = {
    primary: '#007aff',
    secondary: '#5ac8fa',
    danger: '#ff3b30',
    light: '#fff',
    dark: '#000',
  };

  public static imageSizes: any = {
    preload: 12,
    preview: 120,
    large: 750,
  };

  public static imageFallback: any = {
    default: {
      large: './assets/img/default.png',
      preload: './assets/img/default-preload.png',
    },
  };

  public static get usersLogin() {
    return (): string => {
      return `${this.url}/auth/login`;
    };
  }

  public static get refreshToken() {
    return (): any => {
      return `${this.url}/auth/refreshToken`;
    };
  }

  public static get usersMe() {
    return (): string => {
      return `${this.url}/users/me`;
    };
  }


  public static get userLogout(){
    return ():string =>{
      return `${this.url}/auth/logout`;
    };
  }

  

  public static facebook = {
    page: 'https://www.facebook.com/prototype.berlin/',
  };

  public static google = {
    geocoding: {
      key: environment.gecodingKey,
    },
    maps: {
      key: environment.mapKey,
    },
  };

  // {8,100}           - Assert password is between 8 and 100 characters
  // (?=.*[0-9])       - Assert a string has at least one number
  // /^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,100}$/
  public static regex = {
    email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    strongPassword: /^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{8,100}$/,
  };

  public static validation = {
    passwordMinLength: 5,
  };

  public static status = {
    unprocessableEntity: 422,
    unauthorized: 401,
  };
  
  private static matchWildcardString(str: string, rule: string): boolean {
    const escapeRegex = (str): string => str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');

    return new RegExp('^' + rule.split('*').map(escapeRegex).join('.*') + '$').test(str);
  }


  public static matrixServerUrls() {
    return {
      urls: {
        register: environment.matrixUrl + '/_matrix/client/r0/register',
        login: environment.matrixUrl + '/_matrix/client/r0/login'
      }
    }
  }
}
